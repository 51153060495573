import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface FiltersState {
  [groupId: string]: string[];
}

interface FilterStore {
  filters: FiltersState;
  setFilters: (filters: FiltersState) => void;
  clearFilters: () => void;
}

// Define the default filter state
const defaultFilters: FiltersState = {
  dishType: ['any'],
  mainType: ['any'],
  protein: ['any'],
  beverageType: ['any'],
  sideType: ['any'],
  dessertType: ['any'],
  dietary: [],
  cuisine: [],
};

export const useFilterStore = create<FilterStore>()(
  persist(
    (set) => ({
      filters: defaultFilters,
      setFilters: (filters) => set({ filters }),
      clearFilters: () => set({ filters: defaultFilters }),
    }),
    {
      name: 'theme-filters', // storage key
    }
  )
);

// Helper function to clean filters by removing "any" values
export const cleanFilters = (filters: FiltersState): FiltersState => {
  return Object.entries(filters).reduce((acc, [groupId, selectedOptions]) => {
    if (selectedOptions.length === 1 && selectedOptions[0] === 'any') {
      return acc;
    }
    if (selectedOptions.includes('any')) {
      return acc;
    }
    if (selectedOptions.length > 0) {
      acc[groupId] = selectedOptions;
    }
    return acc;
  }, {} as FiltersState);
}; 