import React, { useState, useEffect } from 'react';
import { ButtonContainer } from '../components/ButtonGrid.tsx';
import API_URL from '../config.js';
import ThemeSearchBar from './ThemeSearchBar.tsx';
import { useFilterStore } from '../state/filterStore.ts';

interface ThemeScrollProps {
  onThemeClick: (suggestion: string) => void;
  extractEmojiAndText: (str: string) => { emoji: string; text: string };
  startIndex?: number;
}

const ThemeScroll: React.FC<ThemeScrollProps> = ({
  onThemeClick,
  extractEmojiAndText,
  startIndex = 1,
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isLoadingThemes, setIsLoadingThemes] = useState(true);
  const [page, setPage] = useState(startIndex);
  const [hasMore, setHasMore] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  
  const { filters } = useFilterStore();

  useEffect(() => {
    setSuggestions([]);
    setPage(startIndex);
    setHasMore(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchInitialThemes();
  }, [filters]);

  const loadMore = async () => {
    if (!hasMore || isLoadingThemes) {
      return;
    }
    
    setIsLoadingThemes(true);
    
    try {
      const response = await fetch(`${API_URL}/copilot/suggest-themes/initial?page=${page}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filters })
      });
      const data = await response.json();
      const themes = Array.isArray(data) ? data : [];
      
      if (themes.length === 0) {
        setHasMore(false);
        return;
      }

      setSuggestions(prev => [...prev, ...themes]);
      setPage(prev => prev + 1);
    } catch (error) {
      console.error('Error loading more themes:', error);
      setHasMore(false);
    } finally {
      setIsLoadingThemes(false);
    }
  };

  const fetchInitialThemes = async () => {
    setIsLoadingThemes(true);
    setPage(startIndex);
    try {
      const response = await fetch(`${API_URL}/copilot/suggest-themes/initial?page=${startIndex}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filters })
      });
      const data = await response.json();
      const themes = Array.isArray(data) ? data : [];
      
      if (themes.length === 0) {
        setHasMore(false);
        setSuggestions([
          "Fall Stew",
          "Moroccan Main",
          "Italian Pasta",
          "Vegan Delight",
          "Quick Snacks"
        ]);
        return;
      }

      setSuggestions(themes);
      setPage(startIndex + 1);
    } catch (error) {
      console.error('Error fetching initial themes:', error);
      setSuggestions([
        "Fall Stew",
        "Moroccan Main",
        "Italian Pasta",
        "Vegan Delight",
        "Quick Snacks"
      ]);
    } finally {
      setIsLoadingThemes(false);
    }
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === 'REACHED_BOTTOM' && hasMore && !isLoadingThemes) {
        loadMore();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [page, hasMore, isLoadingThemes, filters]);

  const handleSearch = async () => {
    if (searchInput.trim()) {
      setIsLoadingThemes(true);
      try {
        const response = await fetch(`${API_URL}/copilot/suggest-themes/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: searchInput,
            filters
          })
        });
        
        const data = await response.json();
        const themes = Array.isArray(data) ? data : [];
        
        setSuggestions(themes);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
        if (themes.length > 0) {
          onThemeClick(themes[0]); // Select first theme
        }
        setSearchInput('');
        
      } catch (error) {
        console.error('Error searching themes:', error);
      } finally {
        setIsLoadingThemes(false);
      }
    } else {
      fetchInitialThemes();
    }
  };

  const suggestionButtons = suggestions.map(suggestion => {
    const { emoji, text } = extractEmojiAndText(suggestion);
    return {
      emoji,
      text,
      onClick: () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        onThemeClick(suggestion);
      },
      customWidth: '120px',
      customHeight: 'auto'
    };
  });

  return (
    <div style={{ 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      gap: '0',
      paddingBottom: '160px',
      margin: '0.2rem 0 0 0',
      alignItems: 'center'
    }}>
      <ThemeSearchBar
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearch={handleSearch}
      />
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '1rem',
        margin: '0 auto',
        backgroundColor: 'rgb(248, 249, 250)',
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 4px',
        padding: '1rem',
        width: '100%',
        maxWidth: '800px',
        boxSizing: 'border-box',
        overflowX: 'hidden'
      }}>
        <ButtonContainer 
          buttons={suggestionButtons}
          isLoading={isLoadingThemes}
          skeletonCount={10}
        />
      </div>
    </div>
  );
};

export default ThemeScroll;