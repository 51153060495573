import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase.config.ts';
import { signOut } from 'firebase/auth';
import { useAuthStore } from '../state/authStore.ts';
import API_URL from '../config.js';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { user } = useAuthStore();
  const { setFirebaseToken, clearKrogerTokens, } = useAuthStore();
  const { krogerToken } = useAuthStore();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu]);

  const handleProfileClick = () => {
    setShowMenu(!showMenu);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setFirebaseToken("");
      clearKrogerTokens();
      setShowMenu(false);
      window.location.reload();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleKrogerSignOut = () => {
    clearKrogerTokens();
    setShowMenu(false);
  };

  const getAuthCodeUrl = async () => {
    const stateValue = encodeURIComponent(window.location.pathname);
    const response = await fetch(`${API_URL}/auth_code_url?state=${stateValue}`);
    const data = await response.json();
    window.location.href = data.url;
  };

  const headerStyles = {
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: isMobile ? '0.5rem 1rem' : '0.75rem 1.5rem',
      backgroundColor: '#fafafa',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      height: isMobile ? '60px' : '80px',
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      textDecoration: 'none',
      flex: isMobile ? '0 0 auto' : undefined,
    },
    logo: {
      height: isMobile ? '40px' : '50px',
      width: isMobile ? '40px' : '50px',
    },
    appName: {
      fontSize: '1.5rem',
      color: '#333',
      margin: '0',
      paddingLeft: '10px',
      display: isMobile ? 'none' : 'block',
    },
    navigation: {
      display: 'flex',
      alignItems: 'center',
      gap: isMobile ? '0.75rem' : '1.5rem',
      flex: isMobile ? '1' : undefined,
      justifyContent: isMobile ? 'flex-end' : undefined,
      marginLeft: isMobile ? '1rem' : undefined,
    },
    profilePicture: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      objectFit: 'cover' as const,
      cursor: 'pointer',
      border: '2px solid transparent',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.05)',
        borderColor: '#007bff',
        boxShadow: '0 0 8px rgba(0, 123, 255, 0.3)',
      },
    },
    profileMenu: {
      position: 'absolute',
      top: 'calc(100% + 8px)',
      right: '0',
      backgroundColor: 'white',
      border: '1px solid #eaeaea',
      borderRadius: '8px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      minWidth: '240px',
      zIndex: 2000,
    },
    menuItem: {
      padding: '12px 16px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    menuUserInfo: {
      padding: '16px',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      borderBottom: '1px solid #eaeaea',
    },
    menuProfilePicture: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      objectFit: 'cover' as const,
    },
    menuUserDetails: {
      overflow: 'hidden',
    },
    menuUserName: {
      fontWeight: 500,
      color: '#333',
      marginBottom: '4px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    menuUserEmail: {
      fontSize: '0.9rem',
      color: '#666',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    headerLink: {
      textDecoration: 'none',
      fontSize: isMobile ? '0.9rem' : '1.1rem',
      color: '#333',
      padding: isMobile ? '0.5rem 0.75rem' : '0.5rem 1rem',
      borderRadius: '4px',
      '&:hover': {
        color: '#007bff',
        backgroundColor: 'rgba(0, 123, 255, 0.05)',
      },
    },
  };

  return (
    <header style={headerStyles.header}>
      <Link to="/" style={headerStyles.logoContainer}>
        <img src="/logo512.png" alt="Kitchen Copilot Logo" style={headerStyles.logo} />
        <h1 style={headerStyles.appName}>Kitchen Copilot</h1>
      </Link>
      <nav style={headerStyles.navigation}>
        {user ? (
          <div className="user-section">
            <div style={{ position: 'relative' }} ref={menuRef}>
              {user.photoURL && (
                <img 
                  src={user.photoURL}
                  alt="Profile"
                  style={headerStyles.profilePicture}
                  referrerPolicy="no-referrer"
                  onClick={handleProfileClick}
                />
              )}
              {showMenu && (
                <div style={headerStyles.profileMenu}>
                  <div style={headerStyles.menuUserInfo}>
                    <img 
                      src={user.photoURL}
                      alt="Profile"
                      style={headerStyles.menuProfilePicture}
                      referrerPolicy="no-referrer"
                    />
                    <div style={headerStyles.menuUserDetails}>
                      <div style={headerStyles.menuUserName}>{user.displayName}</div>
                      <div style={headerStyles.menuUserEmail}>{user.email}</div>
                    </div>
                  </div>
                  {krogerToken ? (
                    <div onClick={handleKrogerSignOut} style={headerStyles.menuItem}>
                      Disconnect Kroger
                    </div>
                  ) : (
                    <div onClick={getAuthCodeUrl} style={headerStyles.menuItem}>
                      Connect Kroger
                    </div>
                  )}
                  <div onClick={handleSignOut} style={headerStyles.menuItem}>
                    Sign Out
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <Link to="/login" style={headerStyles.headerLink}>Sign In</Link>
        )}
      </nav>
    </header>
  );
};

export default Header;
