import React, { useState } from 'react';
import { useFilterStore, cleanFilters, FiltersState } from '../state/filterStore.ts';

interface ThemeSearchBarProps {
  searchInput: string;
  setSearchInput: (value: string) => void;
  handleSearch: (filters: FiltersState) => void;
}

// These interfaces help us build reusable filter groups.
interface FilterOptionConfig {
  id: string;
  label: string;
}

interface FilterGroupConfig {
  id: string;
  label: string;
  multiple: boolean; // false = mutually exclusive (radio-like), true = multi-select
  options: FilterOptionConfig[];
  // optional function to decide if this group should be visible based on current filters
  visible?: (filters: FiltersState) => boolean;
}

const ThemeSearchBar: React.FC<ThemeSearchBarProps> = ({
  searchInput,
  setSearchInput,
  handleSearch,
}) => {
  const { filters, setFilters } = useFilterStore();
  
  // Keep only UI state in localStorage
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});

  // Expanded filter groups configuration.
  // NOTE: For the sub-groups that should default to "Any", we add an "any" option
  // (and update the default filter state accordingly).
  const FILTER_GROUPS: FilterGroupConfig[] = [
    {
      id: 'dishType',
      label: 'Dish Type',
      multiple: false,
      options: [
        { id: 'any', label: 'Any' },
        { id: 'main', label: 'Main' },
        { id: 'beverage', label: 'Beverage' },
        { id: 'side', label: 'Side' },
        { id: 'dessert', label: 'Dessert' },
        { id: 'snack', label: 'Snack' },
      ],
    },
    {
      id: 'mainType',
      label: 'Main Dish Type',
      multiple: false,
      options: [
        { id: 'any', label: 'Any Main' },
        { id: 'pasta', label: 'Pasta' },
        { id: 'rice', label: 'Rice Dish' },
        { id: 'soup', label: 'Soup' },
        { id: 'stew', label: 'Stew' },
        { id: 'stir-fry', label: 'Stir Fry' },
        { id: 'roast', label: 'Roast' },
        { id: 'grill', label: 'Grilled' },
        { id: 'sandwich', label: 'Sandwich' },
        { id: 'salad', label: 'Main Salad' },
        { id: 'curry', label: 'Curry' },
      ],
      visible: (filters: FiltersState) =>
        filters['dishType']?.includes('main') || false,
    },
    {
      id: 'protein',
      label: 'Protein',
      multiple: true,
      // Added an "any" option so protein defaults to "any"
      options: [
        { id: 'any', label: 'Any' },
        { id: 'chicken', label: 'Chicken' },
        { id: 'beef', label: 'Beef' },
        { id: 'pork', label: 'Pork' },
        { id: 'fish', label: 'Fish' },
        { id: 'seafood', label: 'Seafood' },
        { id: 'lamb', label: 'Lamb' },
        { id: 'tofu', label: 'Tofu' },
        { id: 'eggs', label: 'Eggs' },
        { id: 'beans', label: 'Beans' },
        { id: 'vegetarian', label: 'Vegetarian' },
        { id: 'vegan', label: 'Vegan' },
      ],
      visible: (filters: FiltersState) =>
        filters['dishType']?.includes('main') || false,
    },
    {
      id: 'beverageType',
      label: 'Beverage Type',
      multiple: false,
      options: [
        { id: 'any', label: 'Any Beverage' },
        { id: 'smoothie', label: 'Smoothie' },
        { id: 'juice', label: 'Juice' },
        { id: 'tea', label: 'Tea' },
        { id: 'coffee', label: 'Coffee' },
        { id: 'cocktail', label: 'Cocktail' },
        { id: 'mocktail', label: 'Mocktail' },
        { id: 'punch', label: 'Punch' },
        { id: 'lemonade', label: 'Lemonade' },
      ],
      visible: (filters: FiltersState) =>
        filters['dishType']?.includes('beverage') || false,
    },
    {
      id: 'sideType',
      label: 'Side Dish Type',
      multiple: false,
      options: [
        { id: 'any', label: 'Any Side' },
        { id: 'salad', label: 'Side Salad' },
        { id: 'vegetable', label: 'Vegetable' },
        { id: 'potato', label: 'Potato' },
        { id: 'rice', label: 'Rice' },
        { id: 'grain', label: 'Other Grain' },
        { id: 'bread', label: 'Bread' },
        { id: 'soup', label: 'Side Soup' },
      ],
      visible: (filters: FiltersState) =>
        filters['dishType']?.includes('side') || false,
    },
    {
      id: 'dessertType',
      label: 'Dessert Type',
      multiple: false,
      // Added an "any" option so dessert type also defaults to "any"
      options: [
        { id: 'any', label: 'Any' },
        { id: 'cake', label: 'Cake' },
        { id: 'cookie', label: 'Cookies' },
        { id: 'pie', label: 'Pie' },
        { id: 'iceCream', label: 'Ice Cream' },
        { id: 'pudding', label: 'Pudding' },
        { id: 'pastry', label: 'Pastry' },
        { id: 'chocolate', label: 'Chocolate' },
        { id: 'fruit', label: 'Fruit Dessert' },
      ],
      visible: (filters: FiltersState) =>
        filters['dishType']?.includes('dessert') || false,
    },
    {
      id: 'dietary',
      label: 'Dietary Needs',
      multiple: true,
      options: [
        { id: 'glutenFree', label: 'Gluten-Free' },
        { id: 'dairyFree', label: 'Dairy-Free' },
        { id: 'nutFree', label: 'Nut-Free' },
        { id: 'lowCarb', label: 'Low-Carb' },
        { id: 'keto', label: 'Keto' },
        { id: 'paleo', label: 'Paleo' },
      ],
    },
    {
      id: 'cuisine',
      label: 'Cuisine',
      multiple: true,
      options: [
        { id: 'italian', label: 'Italian' },
        { id: 'mexican', label: 'Mexican' },
        { id: 'chinese', label: 'Chinese' },
        { id: 'japanese', label: 'Japanese' },
        { id: 'indian', label: 'Indian' },
        { id: 'thai', label: 'Thai' },
        { id: 'mediterranean', label: 'Mediterranean' },
        { id: 'french', label: 'French' },
        { id: 'american', label: 'American' },
        { id: 'korean', label: 'Korean' },
      ],
    },
  ];

  // Toggle the expansion of a particular filter group.
  const toggleGroupExpansion = (groupId: string) => {
    setExpandedGroups(prev => ({ ...prev, [groupId]: !prev[groupId] }));
  };

  // Update handleSearchClick to use cleanFilters helper
  const handleSearchClick = () => {
    const cleanedFilters = cleanFilters(filters);
    handleSearch(cleanedFilters);
  };

  // Update handleFilterClick to use the store
  const handleFilterClick = (groupId: string, optionId: string) => {
    const groupConfig = FILTER_GROUPS.find(g => g.id === groupId);
    if (!groupConfig) return;

    const currentSelected = filters[groupId] || [];
    let newFilters: FiltersState;

    if (groupConfig.multiple) {
      if (optionId === 'any') {
        newFilters = { ...filters, [groupId]: ['any'] };
      } else {
        let newSelection = currentSelected.includes('any')
          ? currentSelected.filter(id => id !== 'any')
          : [...currentSelected];
        if (newSelection.includes(optionId)) {
          newSelection = newSelection.filter(id => id !== optionId);
          if (newSelection.length === 0) {
            newSelection = ['any'];
          }
        } else {
          newSelection.push(optionId);
        }
        newFilters = { ...filters, [groupId]: newSelection };
      }
    } else {
      if (currentSelected[0] === optionId) {
        return;
      } else {
        newFilters = { ...filters, [groupId]: [optionId] };
      }
    }

    setFilters(cleanFilters(newFilters));
    handleSearchClick();

    if (!groupConfig.multiple) {
      setExpandedGroups(prev => ({ ...prev, [groupId]: false }));
    }
  };

  // Helper to style filter buttons dynamically.
  const buttonStyle = (active: boolean) => ({
    padding: '4px 8px',
    fontSize: '12px',
    fontWeight: '500',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    whiteSpace: 'nowrap',
    border: active ? 'none' : '1px solid #007bff',
    backgroundColor: active ? '#007bff' : '#fff',
    color: active ? 'white' : '#007bff',
  });

  // Helper to show a summary for collapsed filter groups.
  const getGroupSummary = (group: FilterGroupConfig) => {
    const selected = filters[group.id] || [];
    if (selected.length === 0) return 'Any';
    if (!group.multiple) {
      const option = group.options.find(opt => opt.id === selected[0]);
      return option ? option.label : 'Any';
    }
    if (selected.includes('any')) return 'Any';
    if (selected.length === 1) {
      const option = group.options.find(opt => opt.id === selected[0]);
      return option ? option.label : 'Any';
    }
    return `${selected.length} selected`;
  };

  return (
    <div
      style={{
        position: 'sticky',
        top: '64px',
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: '#f8f9fa',
        padding: '0.25rem',
        borderBottom: '1px solid #eee',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Top row: search input with Search and Filters buttons */}
        <div
          style={{
            display: 'flex',
            gap: '0.25rem',
            maxWidth: '450px',
            margin: '0 auto',
            width: '100%',
            alignItems: 'center',
            padding: '0 0.25rem',
            boxSizing: 'border-box',
          }}
        >
          <input
            type="text"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            className="search-input"
            style={{
              flex: 1,
              padding: '4px 8px',
              fontSize: '13px',
              border: '1px solid #e9ecef',
              borderRadius: '4px',
              backgroundColor: '#fff',
              transition: 'all 0.2s ease',
              outline: 'none',
              width: '100%',
              height: '28px',
            }}
            onFocus={e => {
              e.target.style.borderColor = '#007bff';
              e.target.style.boxShadow =
                '0 0 0 0.15rem rgba(0,123,255,.20)';
            }}
            onBlur={e => {
              e.target.style.borderColor = '#e9ecef';
              e.target.style.boxShadow = 'none';
            }}
            placeholder="Search by theme..."
            onKeyPress={e => e.key === 'Enter' && handleSearchClick()}
          />
          <button
            onClick={handleSearchClick}
            style={{
              padding: '0 8px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#007bff',
              color: 'white',
              cursor: 'pointer',
              fontSize: '12px',
              fontWeight: '500',
              transition: 'background-color 0.2s ease',
              whiteSpace: 'nowrap',
              boxShadow: '0 1px 1px rgba(0,0,0,0.1)',
              height: '28px',
            }}
            onMouseOver={e =>
              (e.currentTarget.style.backgroundColor = '#0056b3')
            }
            onMouseOut={e =>
              (e.currentTarget.style.backgroundColor = '#007bff')
            }
          >
            Search
          </button>
          <button
            onClick={() => setFiltersOpen(prev => !prev)}
            style={{
              padding: '0 8px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#007bff',
              color: 'white',
              cursor: 'pointer',
              fontSize: '12px',
              fontWeight: '500',
              transition: 'background-color 0.2s ease',
              whiteSpace: 'nowrap',
              boxShadow: '0 1px 1px rgba(0,0,0,0.1)',
              height: '28px',
            }}
            onMouseOver={e =>
              (e.currentTarget.style.backgroundColor = '#0056b3')
            }
            onMouseOut={e =>
              (e.currentTarget.style.backgroundColor = '#007bff')
            }
          >
            Filters
          </button>
        </div>

        {/* Expanded filter panel */}
        {filtersOpen && (
          <div
            style={{
              marginTop: '8px',
              maxWidth: '450px',
              margin: '8px auto 0 auto',
              width: '100%',
              padding: '0 0.25rem',
              boxSizing: 'border-box',
            }}
          >
            {/* For each filter group we show a header with its label and summary.
                Clicking the header toggles expansion to show all options. */}
            {FILTER_GROUPS.filter(
              group => !group.visible || group.visible(filters)
            ).map(group => (
              <div key={group.id} style={{ marginBottom: '12px' }}>
                <div
                  onClick={() => toggleGroupExpansion(group.id)}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '4px 8px',
                    border: '1px solid #007bff',
                    borderRadius: '4px',
                    backgroundColor: '#007bff',
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                >
                  <span>
                    {group.label}: {getGroupSummary(group)}
                  </span>
                  <span>{expandedGroups[group.id] ? '▲' : '▼'}</span>
                </div>
                {expandedGroups[group.id] && (
                  <div
                    style={{
                      marginTop: '8px',
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      gap: '8px',
                    }}
                  >
                    {group.options.map(option => {
                      const active =
                        filters[group.id]?.includes(option.id) || false;
                      return (
                        <button
                          key={option.id}
                          onClick={() =>
                            handleFilterClick(group.id, option.id)
                          }
                          style={buttonStyle(active)}
                        >
                          {option.label}
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ThemeSearchBar;